.home-title {
    align-items: center;
    box-sizing: border-box;
    font-size: 64pt;
    display: flex;
    padding: 20px;
    min-height: calc(100vh - 154px);

    .sub {
        font-size: 36pt;
    }

    span {
        color: #3385c6;
    }
}

@media (min-width: 769px) {
    .home-title {
        margin-top: 80px;
    }
}