nav {
    background-color: #49657b;
    border-bottom: 7.5px solid #4279a3;
    font-weight: 400;
    top: 5px;
    box-sizing: border-box;

    a.logo {
        height: 50px;
        width: 120.63px;
        padding: 9px 25px;

        img {
            height: 50px;
            width: 120.63px;
            position: static;
            left: 0;
            top: 0;
        }
    }

    a {
        text-decoration: none;
        position: relative;
        padding: 22.5px 25px;
        z-index: 3;
        color: #E6E2E0;

        &:hover {
            text-decoration: underline;
            color: #3385c6;
        }
    }
}

@media (max-width: 768px) {
    nav {
        min-height: 80px;
        padding-top: 5px;

        .right {
            width: 100%;
            text-align: center;
    
            a {
                width: auto;
            }
        }

        a.nav-link {
            display: none;

            &.show {
                display: inline-block;
            }
        }
    
        a {
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            display: inline-block;
        }

        .mobile-hamburger {
            display: inline-block;
            font-size: 30px;
            position: absolute;
            right: 20px;
            top: 15.5px;
            color: #E6E2E0;

            i:hover {
                cursor: pointer;
                color: #3385c6;
            }
        }
    }
}


@media (min-width: 769px) {
    nav {
        left: 0;
        position: fixed;
        height: 75px;
        width: 100%;
    
        .right { float: right; }
    
        a {
            float: left;
            color: #E6E2E0;
        }

        .mobile-hamburger {
            display: none;
        }
    }    
}