h1 { font-size: 6rem; }
h2 { font-size: 5rem; }
h3 { font-size: 4rem; }
h4 { font-size: 3rem; }
h5 { font-size: 2rem; }
h6 { font-size: 1rem; }

p {
    font-size: 1.4rem;
}
