@use "typography";
@use "rainbow";
@use "footer";
@use "nav";
@use "homeheader";
@use "pageheader";
@use "home";
@use "error";
@use "gallery";
@use "icons";
@use "post";
@use "fontawesome/fontawesome.min.css";
@use "fontawesome/brands.min.css";
@use "fontawesome/solid.min.css";

@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:400i');

body {
    background-color: #E6E2E0;
    font-family: 'IBM Plex Serif', Helvetica, Arial, sans-serif;
    font-style: italic;
    box-sizing: border-box;
    margin: 0;
    border-top: none;
    color: #656263;
}
