$fa-font-path: "../../assets/fonts";

.fa-duotone {
    --fa-primary-color: #656263;
    --fa-secondary-color: #F04E49;

    &.reversed {
        --fa-primary-color: #F04E49;
        --fa-secondary-color: #656263;

        --fa-primary-opacity: 0.4;
        --fa-secondary-opacity: 1;
    }
}
