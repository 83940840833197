.error-title {
    align-items : center;
    margin-top  : 55px;
    box-sizing  : border-box;
    font-size   : 64pt; 
    display     : flex;
    padding     : 0 150px;
    height      : calc(100vh - 70px);
    top         : 0;

    .title {
        font-size : 150pt;
        color     : #F04E49;
        width     : 100vw;

        .text {
            font-size : 40pt;
            color     : #656263;
        }
    }
}
