.gallery {
    margin: 0 auto;
    display: grid;
    flex-wrap: wrap;
    gap: 10px;

    .gallery-item {
        width: 100%;
        aspect-ratio: 1;
        object-fit: cover;
    }

    a {
        text-align: center;
        text-decoration: none;
        color: #656263;

        img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover; 
        }
    }

    a:hover {
        text-decoration: underline;
        color: #F04E49;
    }
}

.return-link {
    text-align: center;
}

@media (min-width: 500px) {
    .gallery {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 700px) {
    .gallery {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (min-width: 900px) {
    .gallery {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1100px) {
    .gallery {
        grid-template-columns: repeat(5, 1fr);
    }
}