.rainbow {
    background-color : grey;
    overflow         : hidden;
    z-index          : 6;
    height           : 5px;
    width            : 100%; 
    left             : 0;
    top              : 0;

    div {
        position : absolute;
        height   : 100%;
        left     : 0;
        top      : 0;
    }

    .purple {
        background-color : #760089;
        z-index          : 1;
        width            : 100%;
    }

    .blue {
        background-color : #0044FF;
        z-index          : 2;
        width            : calc(calc(100% / 11) * 10);
    }

    .green {
        background-color : #00811F;
        z-index          : 3;
        width            : calc(calc(100% / 11) * 9);
    }

    .yellow {
        background-color : #FFEF00;
        z-index          : 4;
        width            : calc(calc(100% / 11) * 8);
    }

    .orange {
        background-color : #FF8C00;
        z-index          : 5;
        width            : calc(calc(100% / 11) * 7);
    }

    .red {
        background-color : #E70000;
        z-index          : 6;
        width            : calc(calc(100% / 11) * 6);
    }

    .brown {
        background-color : #8a5f3d;
        z-index          : 7;
        width            : calc(calc(100% / 11) * 5);
    }

    .black {
        background-color : #000000;
        z-index          : 8;
        width            : calc(calc(100% / 11) * 4);
    }

    .white {
        background-color : #FFFFFF;
        z-index          : 9;
        width            : calc(calc(100% / 11) * 3);
    }

    .pink {
        background-color : #F7A8B8;
        z-index          : 10;
        width            : calc(calc(100% / 11) * 2);
    }

    .cyan {
        background-color : #55CDFC;
        z-index          : 11;
        width            : calc(calc(100% / 11) * 1);
    }
}

@media (max-width: 768px) {
    .rainbow {
        position: absolute;
    }
}

@media (min-width: 769px) {
    .rainbow {
        position: fixed;
    }
}