.home-red-container {
    background-color: #F04E39;
    text-align: center;
    font-size: 32pt;
    padding: 20pt;
    color: #E6E2E0;

    .fa-solid {
        color: #656263;
    }
}

.page-container {
    border: 7.5px solid #4279a3;
    margin-top: -7.5px;
}

.container {
    padding: 5px;
    padding-bottom: 12px;
    min-height: calc(100vh - 396px);
}

.meta {
    text-align: right;
}
