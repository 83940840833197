.page-header {
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    position: relative;
    overflow: hidden;
    display: flex;
    height: 225px;
    width: 100%;
    
    h2 {
        font-size   : 32pt;
        color : #E6E2E0;
        background-color: rgba(101, 98, 99, 0.6);
        width: 100%;
        text-align: center;
        z-index: -1;
        padding: 5px;
    }
    
    img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: inherit;
        object-fit: cover;
    }

    .credit {
        border-top-left-radius: 10px;
        background-color: #656263;
        position: absolute;
        padding: 10px;
        z-index: 1;
        bottom: 0;
        margin: 0;
        right: 0;
        font-size: 1rem;
        color: #E6E2E0;
        
        a {
            text-decoration: none;
            border-color: #E6E2E0;
            
            &:hover {
                text-decoration: underline;
                color: #F04E49;
            }
        }
    }
}


@media (max-width: 768px) {
    .page-header {
        margin-top: 0;
    }
}
